import { createRouter, createWebHashHistory } from "vue-router";
// 线上正式版本
import index from "../views/index/index.vue";
// 昌邑打包EXE
// import index from "../views/ggcsws/index.vue";
// 临朐打包EXE
// import index from "../views/linquGGWS/index.vue";
// 安丘打包用的
// import index from "../views/ggcsws/index.vue";
// import index from "../views/anqiuGGWS/index.vue";
// 安丘打包EXE把首页替换为公共卫生首页  二维码跳转EXE页面 提交表单后直接跳转至详情页 返回首页为"/""
// import index from '../views/index/laowu.vue'

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
  },
  // 注掉以后劳务派遣详情页路由改为 /
  {
    path: "/laowu",
    name: "laowu",
    component: () => import("@/views/index/laowu.vue"),
  },
  // 人力资源总
  {
    path: "/renliziyuan",
    name: "renliziyuan",
    component: () => import("@/views/index/rlzy.vue"),
  },
  // 出版物总
  {
    path: "/chubanwu",
    name: "chubanwu",
    component: () => import("@/views/index/cbw.vue"),
  },
  {
    path: "/upFile",
    name: "upFile",
    component: () => import("@/views/index/upFile.vue"),
    meta: {
      title: "文件上传",
    },
  },
  {
    path: "/lqupFile",
    name: "LqupFile",
    component: () => import("@/views/index/lqupFile.vue"),
    meta: {
      title: "平面图上传",
    },
  },
  // 劳务派遣变更
  {
    path: "/biangeng",
    children: [
      {
        path: "index",
        name: "biangengIndex",
        component: () => import("@/views/biangeng/index.vue"),
        meta: {
          title: "劳务派遣变更",
        },
      },

      {
        path: "legal",
        name: "legal",
        component: () => import("@/views/biangeng/legal.vue"),
        meta: {
          title: "法人",
        },
      },
      {
        path: "agent",
        name: "agent",
        component: () => import("@/views/biangeng/agent.vue"),
        meta: {
          title: "代理人",
        },
      },
      {
        path: "company",
        name: "company",
        component: () => import("@/views/biangeng/company.vue"),
        meta: {
          title: "企业列表",
        },
      },
      {
        path: "register",
        name: "register",
        component: () => import("@/views/biangeng/register.vue"),
        meta: {
          title: "登记表",
        },
      },
      {
        path: "humanFace",
        name: "humanFace",
        component: () => import("@/views/biangeng/humanFace.vue"),
        meta: {
          title: "人脸验证",
        },
      },
      {
        path: "sign",
        name: "sign",
        component: () => import("@/views/biangeng/sign.vue"),
        meta: {
          title: "签名",
        },
      },
      {
        path: "uploadFile",
        name: "uploadFile",
        component: () => import("@/views/biangeng/uploadFile.vue"),
        meta: {
          title: "文件上传",
        },
      },
      {
        path: "details",
        name: "details",
        component: () => import("@/views/biangeng/details.vue"),
        meta: {
          title: "详情",
        },
      },
      {
        path: "serviceGuide",
        name: "serviceGuide",
        component: () => import("@/views/biangeng/serviceGuide.vue"),
        meta: {
          title: "使用帮助",
        },
      },
    ],
  },
  // 公共卫生许可
  {
    path: "/ggws",
    children: [
      {
        path: "kwindex",
        name: "kwindex",
        component: () => import("@/views/ggwsxk/kwindex.vue"),
        meta: {
          title: "公共卫生许可",
        },
      },
      {
        path: "aqindex",
        name: "aqindex",
        component: () => import("@/views/ggwsxk/aqindex.vue"),
        meta: {
          title: "公共卫生许可",
        },
      },
      {
        path: "legal",
        component: () => import("@/views/ggwsxk/legal.vue"),
        meta: {
          title: "法人办理",
        },
      },
      {
        path: "agent",
        component: () => import("@/views/ggwsxk/agent.vue"),
        meta: {
          title: "代理人办理",
        },
      },
      {
        path: "company",
        component: () => import("@/views/ggwsxk/company.vue"),
        meta: {
          title: "企业列表",
        },
      },
      {
        path: "commitment",
        component: () => import("@/views/ggwsxk/commitment.vue"),
        meta: {
          title: "承诺告知书",
        },
      },
      {
        path: "apply",
        component: () => import("@/views/ggwsxk/apply.vue"),
        meta: {
          title: "申请表",
        },
      },
      {
        path: "humanFace",
        component: () => import("@/views/ggwsxk/humanFace.vue"),
        meta: {
          title: "微信二维码",
        },
      },
      {
        path: "sign",
        component: () => import("@/views/ggwsxk/sign.vue"),
        meta: {
          title: "签名",
        },
      },
      {
        path: "licence",
        component: () => import("@/views/ggwsxk/licence.vue"),
        meta: {
          title: "公共卫生许可",
        },
      },
      {
        path: "terminalLicence",
        component: () => import("@/views/ggwsxk/terminalLicence.vue"),
        meta: {
          title: "公共卫生许可",
        },
      },
      {
        path: "queryLicence",
        component: () => import("@/views/ggwsxk/queryLicence.vue"),
        meta: {
          title: "公共卫生许可",
        },
      },
    ],
  },
  // 人力资源
  {
    path: "/rlzy",
    children: [
      {
        path: "index",
        name: "rlzyIndex",
        component: () => import("@/views/resources/index.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "legal",
        name: "rlzyLegal",
        component: () => import("@/views/resources/legal.vue"),
        meta: {
          title: "法定代表人",
        },
      },
      {
        path: "agent",
        name: "rlzyAgent",
        component: () => import("@/views/resources/agent.vue"),
        meta: {
          title: "代理人",
        },
      },
      {
        path: "company",
        name: "rlzyCompany",
        component: () => import("@/views/resources/company.vue"),
        meta: {
          title: "企业列表",
        },
      },
      {
        path: "register",
        name: "rlzyRegister",
        component: () => import("@/views/resources/register.vue"),
        meta: {
          title: "人力资源",
        },
      },
      {
        path: "humanFace",
        name: "rlzyHumanFace",
        component: () => import("@/views/resources/humanFace.vue"),
        meta: {
          title: "人脸验证",
        },
      },
      {
        path: "sign",
        name: "rlzySign",
        component: () => import("@/views/resources/sign.vue"),
        meta: {
          title: "签名",
        },
      },
      {
        path: "uploadFile",
        name: "rlzyUploadFile",
        component: () => import("@/views/resources/uploadFile.vue"),
        meta: {
          title: "文件上传",
        },
      },
      {
        path: "details",
        name: "rlzyDetails",
        component: () => import("@/views/resources/details.vue"),
        meta: {
          title: "详情",
        },
      },
    ],
  },
  // 人力资源服务业务备案
  {
    path: "/fwyw",
    children: [
      {
        path: "index",
        name: "fwywIndex",
        component: () => import("@/views/rlfwywba/index.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "legal",
        name: "fwywLegal",
        component: () => import("@/views/rlfwywba/legal.vue"),
        meta: {
          title: "法定代表人",
        },
      },
      {
        path: "agent",
        name: "fwywAgent",
        component: () => import("@/views/rlfwywba/agent.vue"),
        meta: {
          title: "代理人",
        },
      },
      {
        path: "company",
        name: "fwywCompany",
        component: () => import("@/views/rlfwywba/company.vue"),
        meta: {
          title: "企业列表",
        },
      },
      {
        path: "register",
        name: "fwywRegister",
        component: () => import("@/views/rlfwywba/register.vue"),
        meta: {
          title: "人力资源",
        },
      },
      {
        path: "humanFace",
        name: "fwywHumanFace",
        component: () => import("@/views/rlfwywba/humanFace.vue"),
        meta: {
          title: "人脸验证",
        },
      },
      {
        path: "sign",
        name: "fwywSign",
        component: () => import("@/views/rlfwywba/sign.vue"),
        meta: {
          title: "签名",
        },
      },
      {
        path: "uploadFile",
        name: "fwywUploadFile",
        component: () => import("@/views/rlfwywba/uploadFile.vue"),
        meta: {
          title: "文件上传",
        },
      },
      {
        path: "details",
        name: "fwywDetails",
        component: () => import("@/views/rlfwywba/details.vue"),
        meta: {
          title: "详情",
        },
      },
    ],
  },
  // 出版物零售单位变更办事指南
  {
    path: "/cbw",
    children: [
      {
        path: "index",
        name: "cbwIndex",
        component: () => import("@/views/publication/index.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "legal",
        name: "cbwLegal",
        component: () => import("@/views/publication/legal.vue"),
        meta: {
          title: "法定代表人",
        },
      },
      {
        path: "agent",
        name: "cbwAgent",
        component: () => import("@/views/publication/agent.vue"),
        meta: {
          title: "代理人",
        },
      },
      {
        path: "company",
        name: "cbwCompany",
        component: () => import("@/views/publication/company.vue"),
        meta: {
          title: "企业列表",
        },
      },
      {
        path: "register",
        name: "cbwRegister",
        component: () => import("@/views/publication/register.vue"),
        meta: {
          title: "出版物",
        },
      },
      {
        path: "humanFace",
        name: "cbwHumanFace",
        component: () => import("@/views/publication/humanFace.vue"),
        meta: {
          title: "人脸验证",
        },
      },
      {
        path: "humanLeper",
        name: "cbwHumanLeper",
        component: () => import("@/views/publication/humanLeper.vue"),
        meta: {
          title: "人脸验证",
        },
      },
      {
        path: "sign",
        name: "cbwSign",
        component: () => import("@/views/publication/sign.vue"),
        meta: {
          title: "签名",
        },
      },
      {
        path: "uploadFile",
        name: "cbwUploadFile",
        component: () => import("@/views/publication/uploadFile.vue"),
        meta: {
          title: "文件上传",
        },
      },
      {
        path: "details",
        name: "cbwDetails",
        component: () => import("@/views/publication/details.vue"),
        meta: {
          title: "详情",
        },
      },
    ],
  },
  // 出版物换证
  {
    path: "/cbwhz",
    children: [
      {
        path: "index",
        name: "cbwHZIndex",
        component: () => import("@/views/publicationHZ/index.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "legal",
        name: "cbwHZLegal",
        component: () => import("@/views/publicationHZ/legal.vue"),
        meta: {
          title: "法定代表人",
        },
      },
      {
        path: "agent",
        name: "cbwHZAgent",
        component: () => import("@/views/publicationHZ/agent.vue"),
        meta: {
          title: "代理人",
        },
      },
      {
        path: "company",
        name: "cbwHZCompany",
        component: () => import("@/views/publicationHZ/company.vue"),
        meta: {
          title: "企业列表",
        },
      },
      {
        path: "register",
        name: "cbwHZRegister",
        component: () => import("@/views/publicationHZ/register.vue"),
        meta: {
          title: "出版物",
        },
      },
      {
        path: "humanFace",
        name: "cbwHZHumanFace",
        component: () => import("@/views/publicationHZ/humanFace.vue"),
        meta: {
          title: "人脸验证",
        },
      },
      {
        path: "sign",
        name: "cbwHZSign",
        component: () => import("@/views/publicationHZ/sign.vue"),
        meta: {
          title: "签名",
        },
      },
      {
        path: "uploadFile",
        name: "cbwHZUploadFile",
        component: () => import("@/views/publicationHZ/uploadFile.vue"),
        meta: {
          title: "文件上传",
        },
      },
      {
        path: "details",
        name: "cbwHZDetails",
        component: () => import("@/views/publicationHZ/details.vue"),
        meta: {
          title: "详情",
        },
      },
    ],
  }, // 出版物注销
  {
    path: "/cbwzx",
    children: [
      {
        path: "index",
        name: "cbwZXIndex",
        component: () => import("@/views/publicationZX/index.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "legal",
        name: "cbwZXLegal",
        component: () => import("@/views/publicationZX/legal.vue"),
        meta: {
          title: "法定代表人",
        },
      },
      {
        path: "agent",
        name: "cbwZXAgent",
        component: () => import("@/views/publicationZX/agent.vue"),
        meta: {
          title: "代理人",
        },
      },
      {
        path: "company",
        name: "cbwZXCompany",
        component: () => import("@/views/publicationZX/company.vue"),
        meta: {
          title: "企业列表",
        },
      },
      {
        path: "register",
        name: "cbwZXRegister",
        component: () => import("@/views/publicationZX/register.vue"),
        meta: {
          title: "出版物",
        },
      },
      {
        path: "humanFace",
        name: "cbwZXHumanFace",
        component: () => import("@/views/publicationZX/humanFace.vue"),
        meta: {
          title: "人脸验证",
        },
      },
      {
        path: "sign",
        name: "cbwZXSign",
        component: () => import("@/views/publicationZX/sign.vue"),
        meta: {
          title: "签名",
        },
      },
      {
        path: "uploadFile",
        name: "cbwZXUploadFile",
        component: () => import("@/views/publicationZX/uploadFile.vue"),
        meta: {
          title: "文件上传",
        },
      },
      {
        path: "details",
        name: "cbwZXDetails",
        component: () => import("@/views/publicationZX/details.vue"),
        meta: {
          title: "详情",
        },
      },
    ],
  },
  // 放射性
  {
    path: "/fsx",
    children: [
      {
        path: "index",
        name: "fsxIndex",
        component: () => import("@/views/radioactivity/index.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "wcindex",
        name: "wcIndex",
        component: () => import("@/views/radioactivity/wcindex.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "legal",
        name: "fsxLegal",
        component: () => import("@/views/radioactivity/legal.vue"),
        meta: {
          title: "法定代表人",
        },
      },
      {
        path: "wclegal",
        name: "fsxWclegal",
        component: () => import("@/views/radioactivity/wclegal.vue"),
        meta: {
          title: "法定代表人",
        },
      },
      {
        path: "agent",
        name: "fsxAgent",
        component: () => import("@/views/radioactivity/agent.vue"),
        meta: {
          title: "代理人",
        },
      },
      {
        path: "company",
        name: "fsxCompany",
        component: () => import("@/views/radioactivity/company.vue"),
        meta: {
          title: "企业列表",
        },
      },
      {
        path: "register",
        name: "fsxRegister",
        component: () => import("@/views/radioactivity/register.vue"),
        meta: {
          title: "医疗机构放射性职业病危害建设项目预评价报告审核",
        },
      },
      {
        path: "examine",
        name: "fsxExamine",
        component: () => import("@/views/radioactivity/examine.vue"),
        meta: {
          title: "放射诊疗建设项目职业病危害放射防护预评价报告审查要点",
        },
      },
      {
        path: "humanFace",
        name: "fsxHumanFace",
        component: () => import("@/views/radioactivity/humanFace.vue"),
        meta: {
          title: "人脸验证",
        },
      },
      {
        path: "sign",
        name: "fsxSign",
        component: () => import("@/views/radioactivity/sign.vue"),
        meta: {
          title: "签名",
        },
      },
      {
        path: "uploadFile",
        name: "fsxUploadFile",
        component: () => import("@/views/radioactivity/uploadFile.vue"),
        meta: {
          title: "文件上传",
        },
      },
      {
        path: "details",
        name: "fsxDetails",
        component: () => import("@/views/radioactivity/details.vue"),
        meta: {
          title: "详情",
        },
      },
    ],
  },
  // 新公共场所卫生
  {
    path: '/ggcsws',
    children: [
      {
        path: 'index',
        name: 'ggcswsIndex',
        component: () => import('@/views/ggcsws/index.vue'),
        meta: {
          title: '公共场所卫生许可证'
        },
      },
      {
        path: 'legals',
        name: 'Legals',
        component: () => import('@/views/ggcsws/legals.vue'),
        meta: {
          title: '公共场所卫生许可证'
        },
      },]
  },
  // 安丘公共场所卫生
  {
    path: '/AQWS',
    children: [
      {
        path: 'index',
        name: 'aqwsindex',
        component: () => import('@/views/anqiuGGWS/index.vue'),
        meta: {
          title: '公共场所卫生许可证'
        },
      }, {
        path: 'legal',
        name: 'aqwslegal',
        component: () => import('@/views/anqiuGGWS/legal.vue'),
        meta: {
          title: '公共场所卫生许可证'
        },
      }, {
        path: "commitment",
        component: () => import("@/views/anqiuGGWS/commitment.vue"),
        meta: {
          title: "承诺告知书",
        },
      },
      {
        path: "apply",
        component: () => import("@/views/anqiuGGWS/apply.vue"),
        meta: {
          title: "申请表",
        },
      },
      {
        path: "sign",
        component: () => import("@/views/anqiuGGWS/sign.vue"),
        meta: {
          title: "签名",
        },
      },
      {
        path: "licence",
        component: () => import("@/views/anqiuGGWS/licence.vue"),
        meta: {
          title: "公共卫生许可",
        },
      },
      {
        path: "queryLicence",
        component: () => import("@/views/anqiuGGWS/queryLicence.vue"),
        meta: {
          title: "公共卫生许可",
        },
      },]
  },
  // 临朐公共场所卫生
  {
    path: '/LQWS',
    children: [
      {
        path: 'index',
        name: 'Lqwsindex',
        component: () => import('@/views/linquGGWS/index.vue'),
        meta: {
          title: '公共场所卫生许可证'
        },
      },
      {
        path: 'agent',
        name: 'Lqagent',
        component: () => import('@/views/linquGGWS/agent.vue'),
        meta: {
          title: '公共场所卫生许可证'
        },
      },
      {
        path: 'apply',
        name: 'Lqapply',
        component: () => import('@/views/linquGGWS/apply.vue'),
        meta: {
          title: '公共场所卫生许可证'
        },
      },
      {
        path: 'company',
        name: 'Lqcompany',
        component: () => import('@/views/linquGGWS/company.vue'),
        meta: {
          title: '公共场所卫生许可证'
        },
      },
      {
        path: 'uploadFile',
        name: 'LquploadFile',
        component: () => import('@/views/linquGGWS/uploadFile.vue'),
        meta: {
          title: '公共场所卫生许可证'
        },
      },
      {
        path: 'licnece',
        name: 'Lqlicnece',
        component: () => import('@/views/linquGGWS/licnece.vue'),
        meta: {
          title: '公共场所卫生许可证'
        },
      },
      {
        path: 'humanFace',
        name: 'LqhumanFace',
        component: () => import('@/views/linquGGWS/humanFace.vue'),
        meta: {
          title: '公共场所卫生许可证'
        },
      },
      {
        path: 'sign',
        name: 'Lqsign',
        component: () => import('@/views/linquGGWS/sign.vue'),
        meta: {
          title: '公共场所卫生许可证'
        },
      }
    ]
  },
  // 邮寄
  {
    path: '/mail',
    children: [
      {
        path: '',
        name: 'mail',
        component: () => import('@/views/mail/index.vue'),
        meta: {
          title: '邮寄'
        },
      },]
  },
  // 户外广告
  {
    path: '/huwai',
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import('@/views/huwai/index.vue'),
        meta: {
          title: '在城市建筑物、设施上张挂、张贴宣传品'
        },
      },
      {
        path: 'legal',
        name: 'Legal',
        component: () => import('@/views/huwai/legal.vue'),
        meta: {
          title: '在城市建筑物、设施上张挂、张贴宣传品'
        },
      },
      {
        path: 'company',
        name: 'Company',
        component: () => import('@/views/huwai/company.vue'),
        meta: {
          title: '在城市建筑物、设施上张挂、张贴宣传品'
        },
      },
      {
        path: 'apply',
        name: 'Apply',
        component: () => import('@/views/huwai/apply.vue'),
        meta: {
          title: '在城市建筑物、设施上张挂、张贴宣传品'
        },
      },
      {
        path: 'applyContent',
        name: 'ApplyContent',
        component: () => import('@/views/huwai/applyContent.vue'),
        meta: {
          title: '在城市建筑物、设施上张挂、张贴宣传品'
        },
      },
      {
        path: 'sign',
        name: 'Sign',
        component: () => import('@/views/huwai/sign.vue'),
        meta: {
          title: '在城市建筑物、设施上张挂、张贴宣传品'
        },
      },
      {
        path: 'verify',
        name: 'Verify',
        component: () => import('@/views/huwai/verify.vue'),
        meta: {
          title: '在城市建筑物、设施上张挂、张贴宣传品'
        },
      },
      {
        path: 'agentCompany',
        name: 'AgentCompany',
        component: () => import('@/views/huwai/agentCompany.vue'),
        meta: {
          title: '在城市建筑物、设施上张挂、张贴宣传品'
        },
      },
      {
        path: 'agentLegal',
        name: 'AgentLegal',
        component: () => import('@/views/huwai/agentLegal.vue'),
        meta: {
          title: '在城市建筑物、设施上张挂、张贴宣传品'
        },
      },
      {
        path: 'upLoadFile',
        name: 'UpLoadFile',
        component: () => import('@/views/huwai/upLoadFile.vue'),
        meta: {
          title: '在城市建筑物、设施上张挂、张贴宣传品'
        },
      },
      {
        path: 'files',
        name: 'Files',
        component: () => import('@/views/huwai/files.vue'),
        meta: {
          title: '在城市建筑物、设施上张挂、张贴宣传品'
        },
      },
    ]
  }
  // // 人力资源
  // {
  //   path: '/rlzy',
  //   children: [
  //     {
  //       path: 'register',
  //       name: 'register',
  //       component: () => import('@/views/resources/register.vue'),
  //       meta: {
  //         title: '人力资源'
  //       },
  //     },]
  // }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    window.document.title = to.meta.title;
  } else {
    window.document.title = "智能审批系统";
  }
  next();
  // if (to.path == '/user/login' || to.path == '/user/register') {
  //     // 登录或者注册才可以往下进行
  //     next();
  // } else {
  //     // 获取 token
  //     const token = localStorage.getItem('Authorization');
  //     // token 不存在
  //     if (token === null || token === '') {
  //         ElMessage.error('您还没有登录，请先登录');
  //         next('/user/login');
  //     } else {
  //         next();
  //     }
  // }
});

export default router;
