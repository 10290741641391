import "core-js/modules/es.array.push.js";
export default {
  name: "",
  data() {
    return {
      list: [
      // { name: "安丘公共卫生", id: 5 },
      {
        name: "安丘劳务派遣",
        id: 2
      }, {
        name: "奎文劳务派遣",
        id: 1
      }, {
        name: "奎文公共卫生",
        id: 3
      }, {
        name: "滨海公共卫生",
        id: 4
      }, {
        name: "寒亭公共卫生",
        id: 6
      },
      // { name: "奎文出版物变更", id: 6 },
      // { name: "奎文出版物换证", id: 7 },
      // { name: "奎文出版物注销", id: 8 },
      {
        name: "奎文放射",
        id: 9
      }, {
        name: "奎文出版物",
        id: 10
      }, {
        name: "奎文人力资源",
        id: 11
      }, {
        name: "潍城放射",
        id: 12
      },
      // { name: "新版公共卫生", id: 13 },
      {
        name: "安丘新版公共卫生",
        id: 14
      }, {
        name: "抗震设防",
        id: 15
      }, {
        name: "临朐公共卫生",
        id: 16
      }, {
        name: "户外广告",
        id: 17
      }]
    };
  },
  created() {
    // console.log('gch');
    console.log('windows');
  },
  methods: {
    chooseTab(id) {
      switch (id) {
        case 1:
          // 奎文劳务派遣经营许可证
          this.$router.push({
            path: "/laowu",
            query: {
              regorg: "370705"
            }
          });
          break;
        case 2:
          // 安丘劳务派遣经营许可证
          this.$router.push({
            path: "/laowu",
            query: {
              regorg: "370784"
              // code: "12345ASDFGH",
            }
          });

          break;
        case 3:
          // this.$router.push({
          //   path: "/ggws/kwindex",
          //   query: {
          //     regorg: "370705",
          //   },
          // });
          this.$router.push({
            path: "/ggcsws/index",
            query: {
              regorg: "370705"
            }
          });
          break;
        case 4:
          // this.$router.push({
          //   path: "/ggws/kwindex",
          //   query: {
          //     regorg: "370727",
          //   },
          // });
          this.$router.push({
            path: "/ggcsws/index",
            query: {
              regorg: "370727"
            }
          });
          break;
        case 5:
          // 安丘公共卫生许可证
          this.$router.push({
            path: "/ggws/aqindex",
            query: {
              regorg: "370784"
              // code: "91370700MA3NRP308Q",
            }
          });

          break;
        case 6:
          // 寒亭公共卫生
          this.$router.push({
            path: "/ggcsws/index",
            // path: "/ggws/kwindex",
            query: {
              regorg: "370703"
            }
          });
          break;
        case 7:
          // 奎文出版物零售单位变更办事指南
          this.$router.push({
            path: "/cbwhz/index",
            query: {
              regorg: "370705"
            }
          });
          break;
        case 8:
          // 奎文出版物零售单位变更办事指南
          this.$router.push({
            path: "/cbwzx/index",
            query: {
              regorg: "370705"
            }
          });
          break;
        case 9:
          // 奎文放射性
          this.$router.push({
            path: "/fsx/index",
            query: {
              regorg: "370705"
            }
          });
          break;
        case 10:
          // 奎文出版物总
          this.$router.push({
            path: "/chubanwu",
            query: {
              regorg: "370705"
            }
          });
          break;
        case 11:
          // 奎文出版物总
          this.$router.push({
            path: "/renliziyuan",
            query: {
              regorg: "370705"
            }
          });
          break;
        case 12:
          // 潍城放射
          this.$router.push({
            path: "/fsx/wcindex",
            query: {
              regorg: "370702"
            }
          });
          break;
        case 13:
          // 新版公共卫生
          this.$router.push({
            path: "/ggcsws/index",
            query: {
              regorg: "370703"
            }
          });
          break;
        case 14:
          // 安丘公共卫生
          this.$router.push({
            path: "/AQWS/index"
          });
          break;
        case 15:
          // 抗震设防
          window.open("http://kzznzd.zwfwpt.cn/#/");
          break;
        case 16:
          // 临朐公共卫生
          this.$router.push({
            path: "/LQWS/index"
          });
          break;
        case 17:
          // 户外广告
          this.$router.push({
            path: "/huwai/index"
          });
          break;
        case 17:
          // 户外广告
          this.$router.push({
            path: "/ggcsws/index",
            query: {
              regorg: "370786"
            }
          });
          break;
      }
    }
  }
};