// import { getLodop } from "./LodopFuncs";
let LODOP = {};
// 法定节假日
var jrdate = [
  '2023-09-29', '2023-09-30', '2023-10-01', '2023-10-02', '2023-10-03', '2023-10-04', '2023-10-05',
  '2023-10-06', '2023-12-30', '2023-12-31', '2024-01-01', '2024-02-10', '2024-02-11', '2024-02-12', '2024-02-13',
  '2024-02-14', '2024-02-15',
  '2024-02-16', '2024-02-17', '2024-04-04', '2024-04-05', '2024-04-06', '2024-05-01', '2024-05-02', '2024-05-03',
  '2024-05-04', '2024-05-05', '2024-06-08', '2024-06-09', '2024-06-10', '2024-09-15', '2024-09-16', '2024-09-17',
  '2024-10-01', '2024-10-02',
  '2024-10-03', '2024-10-04', '2024-10-05', '2024-10-06', '2024-10-07',
]
// 调休列表
var txList = ['2024-02-04', '2024-02-18', '2024-04-07', '2024-04-28', '2024-05-11', '2024-09-14', '2024-09-29', '2024-10-12']
var fun = {
  // 判断日期是否连续，不连续则拆分
  // arr_days是传进来的日期数组
  continueDays(arr_days) {
    // 先排序，然后转时间戳
    let days = arr_days.sort().map((d, i) => {
      let dt = new Date(d);
      dt.setDate(dt.getDate() + 4 - i); // 处理为相同日期
      // 抹去 时 分 秒 毫秒，如果日期带时间可以把这段代码加上
      // dt.setHours(0)
      // dt.setMinutes(0)
      // dt.setSeconds(0)
      // dt.setMilliseconds(0)
      return +dt;
    });
    // 相同日期进行去重
    const newDays = [...new Set(days)];
    let dataArr = [];
    newDays.forEach(element => {
      let arr = [];
      days.forEach((item, i) => {
        if (item == element) {
          arr.push(arr_days[i]);
        }
      });
      dataArr.push(arr);
    });
    // 这里返回的是二维数组
    return dataArr;
  },
  // 判断日期是否在周末
  getWkd(time) {
    if (txList.includes(time)) {
      return false
    } else {
      var date = new Date(time);
      return date.getDay() === 6 || date.getDay() === 0;
    }
  },
  // 获取法定节假日
  getFaDing(e) {
    if (jrdate.includes(e)) {
      return true
    } else {
      return false
    }
  },
  // 获取MAC地址
  getMAC: (acllBack) => {
    LODOP = getLodop();
    var TaskID1, TaskID2;
    TaskID1 = LODOP.GET_SYSTEM_INFO("NetworkAdapter.1.PhysicalAddress");
    TaskID2 = LODOP.GET_SYSTEM_INFO("NetworkAdapter.3.IPAddress");
    CLODOP.On_Return_Remain = true;
    CLODOP.On_Return = function (TaskID, Value) {
      if (TaskID == TaskID1) {
        console.log("Mac", Value);
        configs.printMachineMac = Value;
        acllBack(Value)
      }
      // if (TaskID == TaskID2) {
      //   console.log("IP", Value);
      //   configs.printMachineIp = Value;
      // }
    };
  },
  // 日期转换
  getTime: (time, type) => {
    var date = new Date(time);
    if (type == "-") {
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    } else {
      var Y = date.getFullYear() + "年";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "月";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "日";
    }

    // let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    // let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
    // let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
    // return Y + M + D + h + m + s
    return Y + M + D;
  },
  // 资金转大写
  /** 数字金额大写转换(可以处理整数,小数,负数) */
  dealBigMoney: (n) => {
    var fraction = ["角", "分"];
    var digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
    var unit = [
      ["元", "万", "亿"],
      ["", "拾", "佰", "仟"],
    ];
    // var head = n < 0 ? '欠' : '';
    n = Math.abs(n);

    var s = "";

    for (var i = 0; i < fraction.length; i++) {
      s += (
        digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]
      ).replace(/零./, "");
    }
    s = s || "整";
    n = Math.floor(n);

    for (var i = 0; i < unit[0].length && n > 0; i++) {
      var p = "";
      for (var j = 0; j < unit[1].length && n > 0; j++) {
        p = digit[n % 10] + unit[1][j] + p;
        n = Math.floor(n / 10);
      }
      s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
    }
    // return head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
    return s
      .replace(/(零.)*零元/, "元")
      .replace(/(零.)+/g, "零")
      .replace(/^整$/, "零元整");
  },
  // 验证身份证号
  checkId(idCard) {
    var idCardWi = new Array(
      7,
      9,
      10,
      5,
      8,
      4,
      2,
      1,
      6,
      3,
      7,
      9,
      10,
      5,
      8,
      4,
      2
    ); //将前17位加权因子保存在数组里
    var idCardY = new Array(1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2); //这是除以11后，可能产生的11位余数、验证码，也保存成数组
    var idCardWiSum = 0; //用来保存前17位各自乖以加权因子后的总和
    for (var i = 0; i < 17; i++) {
      idCardWiSum += idCard.substring(i, i + 1) * idCardWi[i];
    }

    var idCardMod = idCardWiSum % 11; //计算出校验码所在数组的位置
    var idCardLast = idCard.substring(17); //得到最后一位身份证号码

    //如果等于2，则说明校验码是10，身份证号码最后一位应该是X
    if (idCardMod == 2) {
      if (idCardLast == "X" || idCardLast == "x") {
        return true;
      } else {
        return false;
      }
    } else {
      //用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
      if (idCardLast == idCardY[idCardMod]) {
        return true;
      } else {
        return false;
      }
    }
  },
  checkRegorg(regorgCn) {
    if (regorgCn.includes("奎文")) {
      return '370705'
    }
    if (regorgCn.includes("滨海")) {
      return '370727'
    }
    if (regorgCn.includes("潍城")) {
      return '370702'
    }
    if (regorgCn.includes("寒亭")) {
      return '370703'
    }
    if (regorgCn.includes("安丘")) {
      return '370784'
    }
    if (regorgCn.includes("临朐")) {
      return '370724'
    }
    return '370700'
  }

};
export default fun;
